import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from '../Navbaar/Navbar';

import Footer from '../components/Conatiner/Footer/Footer.js';

const LayOut = () => {
  return (
    <div>
      <Navbar />

      <Outlet />
      
        <Footer />
    </div>
  );
};

export default LayOut;
