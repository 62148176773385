// import { useState } from "react";
// import { Link } from "react-router-dom";
// import bluebuks from '../creaditcardComponents/cardTypeImages/Blubucks.png'
// import RequestaCallback from "../components/EnquiryForm/RequestaCallback";
// export default function Navbar() {
//   const [isMenuOpen, setIsMenuOpen] = useState(false);

//   const toggleMenu = () => {
//     setIsMenuOpen(!isMenuOpen);
//   };
// const [showmodal,setShowModal] = useState(false)
//   return (
//     <nav className="bg-white shadow-md">
//       <RequestaCallback setshowModal={setShowModal} showModal={showmodal}/>
//       <div className="flex items-center justify-between p-4">
//         <div className="flex items-center space-x-4">
//          <Link to='/' style={{    maxWidth: "210px",
//     height: "36px",
//     overflow: "hidden",
//     display: "flex",
//     alignItems: "center"}}>
//          <img src= {bluebuks} alt="Logo" style={{width:"100%",objectFit:"contain"}}/>
//          </Link>
//           <div className="hidden lg:flex space-x-4">
//             <Link to='/about-us' className="text-lg font-semibold">
//               About Us
//             </Link>
//             <li class="nav-item dropdown list-none">
//           <a className="nav-link dropdown-toggle text-lg font-semibold flex items-center" role="button" data-bs-toggle="dropdown" aria-expanded="false">
//           Loans
//           </a>
//           <ul class="dropdown-menu">
//             <li> <Link  to='/personal-loan' className="block px-4 py-2 hover:bg-gray-100 text-nowrap">
//                     Personal Loan
//                   </Link></li>
//             <li><Link to='/home-loan' className="block px-4 py-2 hover:bg-gray-100 text-nowrap">
//                     Home Loan
//                    </Link></li>
//             <li>  <Link to='/business-loan' className="block px-4 py-2 hover:bg-gray-100 text-nowrap">
//                     Business Loan
//                   </Link></li>
//                   <li> <Link to='/business-against-property' className="block px-4 py-2 hover:bg-gray-100 text-nowrap">
//                     Loan Against Property
//                    </Link></li>
                   
//                     <li> <Link to='/Working-Capital-OverDraft-limit' className="block px-4 py-2 hover:bg-gray-100 text-nowrap">
//                     Working Capital /OverDraft limit
//                    </Link></li>
//           </ul>
//         </li>
           
//             <Link  to='/contact-us' className="text-lg font-semibold">
//               Contact Us
//             </Link>

//             <Link to="/become-a-partner" className="text-lg font-semibold">
//               Become a Partner
//             </Link>
//             <a href="#" className="text-lg font-semibold">
//               <span className="bg-[#0F63AC] text-white px-2 py-1 rounded">Coming Soon</span> Check Cibil Score
//             </a>
//           </div>
//         </div>
//         <div className="hidden lg:flex lg:items-center">
//           <a href="#" className="text-lg text-white font-semibold bg-[#0F63AC] px-4 py-2 rounded-md shadow-xl" onClick={()=>setShowModal(true)}>
//             DOWNLOAD APP
//           </a>
//         </div>
//         <div className="lg:hidden">
//           <button onClick={toggleMenu} className="text-2xl">
//             {isMenuOpen ? "✖" : "☰"}
//           </button>
//         </div>
//       </div>

//       {isMenuOpen && (
//         <div className="lg:hidden flex flex-col space-y-2 p-4">
//          <Link to='/about-us' className="text-lg font-semibold">
//               About Us
//             </Link>
//           <li class="nav-item dropdown list-none">
//           <a className="nav-link dropdown-toggle text-lg font-semibold flex items-center" role="button" data-bs-toggle="dropdown" aria-expanded="false">
//           Loans
//           </a>
//           <ul class="dropdown-menu">
//             <li> <Link  to='/personal-loan' className="block px-4 py-2 hover:bg-gray-100">
//                     Personal Loan
//                   </Link></li>
//             <li><Link to='/home-loan' className="block px-4 py-2 hover:bg-gray-100">
//                     Home Loan
//                    </Link></li>
//             <li>  <Link to='/business-loan' className="block px-4 py-2 hover:bg-gray-100">
//                     Business Loan
//                   </Link></li>
//             <li> <Link to='/business-against-property' className="block px-4 py-2 hover:bg-gray-100">
//                     Loan Against Property
//                    </Link></li>
//           </ul>
//         </li>
        
//         <Link  to='/contact-us' className="text-lg font-semibold">
//               Contact Us
//             </Link>

//             <Link to="/become-a-partner" className="text-lg font-semibold">
//               Become a Partner
//             </Link>
//             <a href="#" className="text-lg font-semibold">
//               <span className="bg-[#0F63AC] text-white px-2 py-1 rounded">Coming Soon</span> Check Cibil Score
//             </a>
//           <a href="#" className="text-lg text-white font-semibold bg-[#0F63AC] px-4 py-2 rounded-md" onClick={()=>setShowModal(true)}>
//            DOWNLOAD APP
//           </a>
//         </div>
//       )}
//     </nav>
//   );
// }


import { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Search,X, ChevronRight, Mail, Phone, Facebook, Twitter, Instagram, Youtube, ArrowRight } from 'lucide-react'
import bluebuks from '../creaditcardComponents/cardTypeImages/Blubucks.png'
import { Link } from 'react-router-dom'
import RequestaCallback from '../components/EnquiryForm/RequestaCallback'

const menuItems = [
  { 
    title: 'About Us',
    path: '/about-us',
    // submenu: [
    //   { title: 'Home One', path: '/home-1' },
    //   { title: 'Home Two', path: '/home-2' },
    //   { title: 'Home three', path: '/home-2' },
    //   { title: 'Home four', path: '/home-2' },
    //   { title: 'Home fifth', path: '/home-2' },
      

    // ]
  },
  { 
    title: ' Loans',
    // path: '/services',
    submenu: [
      { title: 'Home Loan', path: '/home-loan' },
      { title: 'Personal Loan', path: '/personal-loan' },
      { title: 'Business Loan', path: '/business-loan' },
      { title: 'Loan Against Property', path: '/business-against-property' },
      { title: 'Working Capital /OverDraft limit', path: '/Working-Capital-OverDraft-limit' },
    ]
  },
  { 
    title: 'Contact Us',
    path: '/contact-us',
    // submenu: [
    //   { title: 'FAQ', path: '/faq' },
    //   { title: 'Testimonials', path: '/testimonials' },
    //   { title: 'Testimonials', path: '/testimonials' },
    //   { title: 'Testimonials', path: '/testimonials' },
    //   { title: 'Testimonials', path: '/testimonials' },
    // ]
  },
  { 
    title: 'Become a Partner',
    path: '/become-a-partner',
    // submenu: [
    //   { title: 'Products', path: '/products' },
    //   { title: 'Cart', path: '/cart' },
    //   { title: 'Cart', path: '/cart' },
    //   { title: 'Cart', path: '/cart' },
    //   { title: 'Cart', path: '/cart' },
    // ]
  },
]

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false)
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const [activeSubmenu, setActiveSubmenu] = useState(null)
  const [expandedMobileMenu, setExpandedMobileMenu] = useState(null)

  const toggleMenu = () => setIsOpen(!isOpen)
  const toggleSearch = () => setIsSearchOpen(!isSearchOpen)
  const [showmodal,setShowModal] = useState(false)

  return (
    <nav className=" w-full z-50 bg-white shadow-md">
      <RequestaCallback setshowModal={setShowModal} showModal={showmodal}/>

      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-20">
          {/* Logo */}

         <Link to='/' className="flex-shrink-0">
                      <img src= {bluebuks} alt="Logo"  style={{width:"100%",objectFit:"contain"}} className="h-10"/>
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            {menuItems.map((item) => (
              <div
                key={item.title}
                className="relative group"
                onMouseEnter={() => setActiveSubmenu(item.title)}
                onMouseLeave={() => setActiveSubmenu(null)}
              >
                <a
                  href={item.path}
                  className={`text-sm font-medium transition-colors duration-200 ${
                    item.title === 'Home' || item.title === ''
                      ? 'text-cyan-400'
                      : 'text-gray-700 hover:text-cyan-400'
                  }`}
                >
                  {item.title}
                </a>
                
                {/* Desktop Submenu */}
                <AnimatePresence>
                  {activeSubmenu === item.title && item.submenu && (
                    <motion.div
                      initial={{ opacity: 0, y: 20, scaleY: 0.8 }}
                      animate={{ opacity: 1, y: 0, scaleY: 1 }}
                      exit={{ opacity: 0, y: 20, scaleY: 0.8 }}
                      transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
                      style={{ transformOrigin: "top" }}
                      className="absolute left-0  w-64 bg-white rounded-md shadow-lg py-10 z-50"
                    >
                      {item.submenu.map((subItem, index) => (
                        <motion.a
                          key={subItem.title}
                          href={subItem.path}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-cyan-500 hover:text-white hover:border-s-2 hover:border-indigo-500 hvr-bounce-to-right "
                          initial={{ opacity: 0, x: -20 }}
                          animate={{ opacity: 1, x: 0 }}
                          transition={{ duration: 0.3, delay: index * 0.1 }}
                        >
                          {subItem.title}
                        </motion.a>
                      ))}
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            ))}
          </div>

          {/* Right Section */}
          <div className="flex items-center space-x-4">
            <button 
              className="p-2 hover:text-cyan-400 transition-colors"
              onClick={toggleSearch}
            >
              <Search className="w-5 h-5" />
            </button>
          
            <button  onClick={()=>setShowModal(true)} className="hidden md:flex items-center bg-cyan-400 text-white px-6 py-2 rounded hover:bg-cyan-500 transition-colors">
              <span className="mr-2">APPLY FOR LOAN</span>
              <ArrowRight className="w-4 h-4" />
            </button>
            {/* Mobile Menu Button */}
            <button
              onClick={toggleMenu}
              className="md:hidden p-2 text-gray-600 hover:text-cyan-400 transition-colors"
            >
              <div className="space-y-1.5">
                <div className="w-6 h-0.5 bg-current"></div>
                <div className="w-6 h-0.5 bg-current"></div>
                <div className="w-6 h-0.5 bg-current"></div>
              </div>
            </button>
          </div>
        </div>
      </div>

      {/* Search Overlay */}
      <AnimatePresence>
        {isSearchOpen && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="absolute inset-x-0 top-0 bg-white shadow-lg p-4"
          >
            <div className="container mx-auto flex items-center">
              <button 
                onClick={toggleSearch}
                className="p-3 mr-2 text-gray-600 hover:text-cyan-400 transition-colors"
              >
                <X className="w-6 h-6" />
              </button>
              <input
                type="text"
                placeholder="Search Here..."
                className="flex-1 p-3 outline-none text-lg"
                autoFocus
              />
              <button 
                onClick={toggleSearch}
                className="p-3 bg-cyan-400 text-white hover:bg-cyan-500 transition-colors"
              >
                <Search className="w-6 h-6" />
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Mobile Menu */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}
            transition={{ type: 'tween', duration: 0.3 }}
            className="fixed inset-0 bg-[#1e1b4b] z-50 md:hidden overflow-y-auto"
          >
            <div className="flex flex-col h-full p-6">
              <div className="flex justify-between items-center mb-8">
              <Link to='/' className="flex-shrink-0">
                      <img src= {bluebuks} alt="Logo"  style={{width:"100%",objectFit:"contain"}} className="h-10"/>
          </Link>
                <button
                  onClick={toggleMenu}
                  className="p-2 text-white hover:text-cyan-400 transition-colors"
                >
                  <X className="w-6 h-6" />
                </button>
              </div>

              <div className="flex-1 overflow-y-auto">
                {menuItems.map((item, index) => (
                  <div key={item.title}>
                    <motion.button
                      initial={{ opacity: 0, x: 20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: index * 0.1 }}
                      className="flex items-center justify-between w-full py-4 text-white hover:text-cyan-400 transition-colors border-b border-gray-700"
                      onClick={() => setExpandedMobileMenu(expandedMobileMenu === item.title ? null : item.title)}
                    
                    >
                      <a
                  href={item.path}pan className="text-xl font-medium">{item.title}</a>
                   {item.submenu && (     <ChevronRight className={`w-5 h-5 text-cyan-400 transform transition-transform ${
                        expandedMobileMenu === item.title ? 'rotate-90' : ''
                      }`} />)}
                    </motion.button>
                    
                    <AnimatePresence>
                      {expandedMobileMenu === item.title && item.submenu && (
                        <motion.div
                          initial={{ height: 0, opacity: 0 }}
                          animate={{ height: 'auto', opacity: 1 }}
                          exit={{ height: 0, opacity: 0 }}
                          transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
                          className="overflow-hidden"
                        >
                          {item.submenu.map((subItem, index) => (
                            <motion.a
                              key={subItem.title}
                              href={subItem.path}
                              className="block py-3 pl-6 text-gray-300 hover:text-cyan-400 transition-colors"
                              initial={{ opacity: 0, x: -20 }}
                              animate={{ opacity: 1, x: 0 }}
                              transition={{ duration: 0.3, delay: index * 0.1 }}
                            >
                              {subItem.title}
                            </motion.a>
                          ))}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                ))}
              </div>

              <div className="mt-auto space-y-6">
                <div className="space-y-4">
                  <a 
                    href="mailto:needhelp@easilon.com"
                    className="flex items-center text-white hover:text-cyan-400 transition-colors"
                  >
                    <Mail className="w-5 h-5 mr-3 text-cyan-400" />
                    support@blubucks.in
                  </a>
                  <a 
                    href="tel:+918505965050"
                    className="flex items-center text-white hover:text-cyan-400 transition-colors"
                  >
                    <Phone className="w-5 h-5 mr-3 text-cyan-400" />
                    +91 8505965050
                  </a>
                </div>

                <div className="flex space-x-4">
                <a href="https://wa.me/9717808035" className="text-2xl text-cyan-400 hover:text-green-500">
    <i className="fab fa-whatsapp"></i>
  </a>
  <a href="https://x.com/Bluebucksloan" className="text-2xl text-cyan-400 hover:text-red-500">
    <i className="fas fa-times"></i>
  </a>
  <a href="https://www.facebook.com/profile.php?id=615615598849" className="text-2xl text-cyan-400 hover:text-blue-600">
    <i className="fab fa-facebook"></i>
  </a>
  <a href="https://www.linkedin.com/in/bluebucks-asset-49ba80255/" className="text-2xl text-cyan-400 hover:text-blue-800">
    <i className="fab fa-linkedin-in"></i>
  </a>
  <a href="https://www.instagram.com/blubucks_loan/" className="text-2xl text-cyan-400 hover:text-pink-600">
    <i className="fab fa-instagram"></i>
  </a>
  <a href="mailto:support@blubucks.in"className="text-2xl text-cyan-400 hover:text-gray-400">
    <i className="fas fa-envelope"></i>
  </a>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  )
}

