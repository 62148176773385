import React from "react";
import styles from "./Footer.module.css";
import { Link } from "react-router-dom";
import brand from "./images/Blubucks.png"
const Footer = () => {
  return (
    <div className={styles.footerWrapper}>
      <div className={styles.loanBannerContainer}>
        <div className={styles.loanBannerContent}>
          <div className={styles.loanBannerLeft}>
            <h4 className={styles.loanHeading}>
              <span className={styles.loanLine}></span>
              GET A QUICK LOAN
            </h4>
            <h1>Get A Business Loans Quickly</h1>
          </div>
          <div className={styles.loanBannerRight}>
            <button className={styles.applyLoanBtn}>Apply for Loan &nbsp; →</button>
          </div>
        </div>
      </div>
      <footer className={styles.footer}>
        <div className={styles.footerMain}>
          <div className={styles.footerSection}>
            <img src={brand} alt="Easilon Logo" className={styles.footerLogo} />
            <p className={styles.slogan}>
            At Blubucks Asset Pvt Ltd, we empower dreams with the support you need to turn aspirations into reality. With a commitment to trust, transparency, and excellence, we're here to bridge the gap between where you are and where you want to be.
            </p>
            <form className={styles.emailForm}>
              <input type="email" placeholder="Enter Email" />
              <button type="submit">&#8594;</button>
            </form>
          </div>
          <div className={styles.footerSection}>
            <h5>Company & Products</h5>
            <ul>
            <li><Link to="/about-us" className="hover:text-gray-400">About Us</Link></li>
                <li><Link to="/contact-us" className="hover:text-gray-400">Contact Us</Link></li>
                <li><Link to="/privacy-policy" className="hover:text-gray-400">Privacy Policy</Link></li>
                <li><Link to="/data-storage-policy" className="hover:text-gray-400">Data Storage Policy</Link></li>
                <li><Link to="/terms-and-condition" className="hover:text-gray-400">Terms & Condition</Link></li>
            </ul>
          </div>
          <div className={styles.footerSection}>
            <h5>Loan Services</h5>
            <ul>
            <li><Link to="/business-against-property" className="hover:text-gray-400">Property Loan</Link></li>
                <li><Link to="/home-loan" className="hover:text-gray-400">Home Loan</Link></li>
                <li><Link to="/personal-loan" className="hover:text-gray-400">Personal Loan</Link></li>
                <li><Link to="/business-loan" className="hover:text-gray-400">Business Loan</Link></li>
                <li><Link to="/Working-Capital-OverDraft-limit" className="hover:text-gray-400">Working Capital/OverDraft limit</Link></li>
            </ul>
          </div>
          <div className={styles.footerSection}>
            <h5>Most Searched Link</h5>
            <ul>
            <li><Link to="/refer-and-earn" className="hover:text-gray-400">Refer & Earn</Link></li>
            <li><Link to="/become-a-partner" className="hover:text-gray-400">Become a Partner</Link></li>
            </ul>
          </div>
          <div className={styles.footerSection}>
            <h5>Get In Touch</h5>
            <p className='mb-2'><i class="fa-solid fa-location-dot"></i> <span className="hover:text-gray-400">706 , 707 vishwadeep Tower District Centre Janakpuri Delhi 110058</span></p>
              <p><i class="fa-solid fa-phone"></i> <a href="tel:8505965050" className="hover:text-gray-400">+91 8505965050</a></p>
              <p><i class="fa-solid fa-phone"></i> <a href="tel:8505975050" className="hover:text-gray-400">+91 8505975050</a></p>
          </div>
        </div>
        <div className={styles.footerBottom}>
          <div className={styles.socialIcons}>
          <a href="https://wa.me/9717808035" className="text-2xl hover:text-green-500">
    <i className="fab fa-whatsapp"></i>
  </a>
  <a href="https://x.com/Bluebucksloan" className="text-2xl hover:text-red-500">
    <i className="fas fa-times"></i>
  </a>
  <a href="https://www.facebook.com/profile.php?id=615615598849" className="text-2xl hover:text-blue-600">
    <i className="fab fa-facebook"></i>
  </a>
  <a href="https://www.linkedin.com/in/bluebucks-asset-49ba80255/" className="text-2xl hover:text-blue-800">
    <i className="fab fa-linkedin-in"></i>
  </a>
  <a href="https://www.instagram.com/blubucks_loan/" className="text-2xl hover:text-pink-600">
    <i className="fab fa-instagram"></i>
  </a>
  <a href="mailto:support@blubucks.in"className="text-2xl hover:text-gray-400">
    <i className="fas fa-envelope"></i>
  </a>
          </div>
          <p>© Copyright 2024 BluBucks — All Rights Reserved</p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
