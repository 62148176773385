import React, { Suspense, lazy } from 'react';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import LayOut from '../Layout/LayOut';
import Loader from '../components/Conatiner/Loader/Loader';

// Lazy load components
const CreditCardNavbar = lazy(() => import('../components/Creditcard/Creditcard'));
const HomeLoanNavbar = lazy(() => import('../Loans/homeLoan/HomeLoanNavbar'));
const PersonalLoanNavbar = lazy(() => import('../Loans/personalLoan/PersonalLoanNavbar'));
const PropertNavbar = lazy(() => import('../Loans/loanagainstproperty/PropertNavbar'));
const BussinessLoanNavbar = lazy(() => import('../Loans/bussinessLoan/BussinessLoanNavbar'));
const PersonalLoanDsa = lazy(() => import('../Loans/dsa/PersonalLoanDsa/PersonalLoanDsa'));
const HomeLoanDsa = lazy(() => import('../Loans/dsa/homeLoanDsa/HomeLoanDsa'));
const BussinLoanDsa = lazy(() => import('../Loans/dsa/bussinessLoan/BussinessLoanDsa'));
const LoanAgainstProperty = lazy(() => import('../Loans/dsa/LoanAgainstProperty/LoanAgainstProperty'));
const Contact = lazy(() => import('../components/Contact/Contact'));
const TopBanner = lazy(() => import('../components/About/TopBanner'));
const Refer = lazy(() => import('../components/Refer&Earn/Refer'));
const Franchisee = lazy(() => import('../components/Franchisee/Franchisee'));
const PrivacyPolicy = lazy(() => import('../components/Privacy/PrivacyPolicy'));
const WorkingCapitalNavbar = lazy(() => import('../Loans/loanagainstproperty/WorkingCapitalNavbar'));
const DataStoragePolicy = lazy(() => import('../PrivacyandSecurity/DataStoragePolicy'));
const TermsAndConditions = lazy(() => import('../PrivacyandSecurity/TermsAndCondition'));
const Home = lazy(() => import('../components/Conatiner/Home/Home'));

const LoansRoute = () => {
  const route = createBrowserRouter(createRoutesFromElements(
    <Route path='/' element={<LayOut />}>
      <Route path='/' element={
        <Suspense fallback={<Loader />}>
          <Home />
        </Suspense>
      } />
      <Route path='/data-storage-policy' element={
        <Suspense fallback={<Loader />}>
          <DataStoragePolicy />
        </Suspense>
      } />
      <Route path='/privacy-policy' element={
        <Suspense fallback={<Loader />}>
          <PrivacyPolicy />
        </Suspense>
      } />
      <Route path='/terms-and-condition' element={
        <Suspense fallback={<Loader />}>
          <TermsAndConditions />
        </Suspense>
      } />
      <Route path='/credit-cards' element={
        <Suspense fallback={<Loader />}>
          <CreditCardNavbar />
        </Suspense>
      } />
      <Route path='/about-us' element={
        <Suspense fallback={<Loader />}>
          <TopBanner />
        </Suspense>
      } />
      <Route path='/become-a-partner' element={
        <Suspense fallback={<Loader />}>
          <Franchisee />
        </Suspense>
      } />
      <Route path='/refer-and-earn' element={
        <Suspense fallback={<Loader />}>
          <Refer />
        </Suspense>
      } />
      <Route path='/contact-us' element={
        <Suspense fallback={<Loader />}>
          <Contact />
        </Suspense>
      } />
      <Route path='/home-loan' element={
        <Suspense fallback={<Loader />}>
          <HomeLoanNavbar />
        </Suspense>
      } />
      <Route path='/personal-loan' element={
        <Suspense fallback={<Loader />}>
          <PersonalLoanNavbar />
        </Suspense>
      } />
      <Route path='/business-loan' element={
        <Suspense fallback={<Loader />}>
          <BussinessLoanNavbar />
        </Suspense>
      } />
      <Route path='/business-against-property' element={
        <Suspense fallback={<Loader />}>
          <PropertNavbar />
        </Suspense>
      } />
      <Route path='/personal-loan-dsa' element={
        <Suspense fallback={<Loader />}>
          <PersonalLoanDsa />
        </Suspense>
      } />
      <Route path='/Working-Capital-OverDraft-limit' element={
        <Suspense fallback={<Loader />}>
          <WorkingCapitalNavbar />
        </Suspense>
      } />
      <Route path='/home-loan-dsa' element={
        <Suspense fallback={<Loader />}>
          <HomeLoanDsa />
        </Suspense>
      } />
      <Route path='/bussiness-loan-dsa' element={
        <Suspense fallback={<Loader />}>
          <BussinLoanDsa />
        </Suspense>
      } />
      <Route path='/loanagainst-property-dsa' element={
        <Suspense fallback={<Loader />}>
          <LoanAgainstProperty />
        </Suspense>
      } />
    </Route>
  ));

  return <RouterProvider router={route} />;
};

export default LoansRoute;
